<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">退货单 - 待完成</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectBack" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectBack" clearable maxlength="20"
                style="width: 280px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-select v-if="isGetBook" class="family" size="small" v-model="query.get" placeholder="代退状态"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="非代退"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="代退"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.pay" placeholder="结算方式"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="日结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="月结"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='2' label="预付"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-select class="family" size="small" v-model="query.account" placeholder="退款状态"
                 clearable style="width: 100px;margin-right: 10px">
        <el-option value='0' label="未退款"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        <el-option value='1' label="已退款"
                   style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
      </el-select>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectBack">查询</el-button>
    </div>
    <div class="selectdiv" style="margin-top: 30px;margin-bottom: 47px">
      <el-button type="success" size="small" icon="el-icon-plus" @click="addBack">新增退货</el-button>
      <el-button type="primary" size="small" icon="el-icon-download"
                 :loading="loadingDlo" @click="download">下载选中
      </el-button>
      <el-button type="success" size="small" icon="el-icon-edit"
                 @click="openCommentBatch">备注选中
      </el-button>
      <el-popover placement="bottom" v-model="visibleAcc">
        <div style="display: flex;justify-content: center;align-items: center">
          <span class="spanPopo">确定记账？</span>
          <el-input class="family" size="mini" v-model.trim="accCheck"
                    placeholder="防误操作，输入1234" maxlength="4"
                    @keyup.enter.native="updAccountBatch"
                    style="width: 170px !important">
          </el-input>
          <el-button class="buttonPopo" size="mini" type="success" @click="updAccountBatch"
                     icon="el-icon-check" :loading="loadingAcc"/>
        </div>
        <el-button slot="reference" type="warning" size="small" style="margin-left: 10px"
                   icon="el-icon-notebook-2" :loading="loadingAcc" @click="initAccCheck">记账选中
        </el-button>
      </el-popover>
      <el-popover placement="bottom" v-model="visibleCom">
        <div style="display: flex;justify-content: center;align-items: center">
          <span class="spanPopo">确定完成？</span>
          <el-input class="family" size="mini" v-model.trim="comCheck"
                    placeholder="防误操作，输入1234" maxlength="4"
                    @keyup.enter.native="updComplateBatch"
                    style="width: 170px !important">
          </el-input>
          <el-button class="buttonPopo" size="mini" type="success" @click="updComplateBatch"
                     icon="el-icon-check" :loading="loadingCom"/>
        </div>
        <el-button slot="reference" type="success" size="small"
                   style="margin-left: 10px" @click="initComCheck"
                   icon="el-icon-check" :loading="loadingCom">完成选中
        </el-button>
      </el-popover>
      <el-button type="text" size="samll" style="margin-left: 10px;height: 22px !important;"
                 icon="el-icon-document-checked" @click="getComplate">已完成退货
      </el-button>
    </div>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true" style="margin-top: 0;margin-bottom: 25px"/>
    <div v-if="total>0" style="height: 23px;line-height: 13px">
      <span class="span" style="color: #606266">已选 </span>
      <span class="span" style="color: darkorange">{{this.$refs.multipleTable.selection.length}}</span>
      <span class="span" style="color: #606266"> 条 (可分页)</span>

      <span class="span" style="color: #606266;;margin-left: 30px">搜索出 </span>
      <span class="span" style="color: #409EFF">{{total}}</span>
      <span class="span" style="color: #606266"> 条结果</span>

      <span class="span" style="color: #606266;margin-left: 30px">退数 </span>
      <span class="span" style="color: #409EFF">{{countnum}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">书款 </span>
      <span class="span" style="color: #409EFF">{{parseFloat(countprice).toFixed(2)}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">退款 </span>
      <span class="span" style="color: #EB6100">{{parseFloat(countpay).toFixed(2)}}</span>
    </div>
    <el-table border stripe size="small" :data="backs" :style="{width: '1000px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :row-style="{height:'42px'}" :row-key="getRowKeys"
              @select="pinSelect" ref="multipleTable">
      <el-table-column type="selection" :reserve-selection="true" width="40px" key="0"
                       header-align="center" align="center"/>
      <el-table-column label="退货时间" prop="time" width="100" :resizable="false"
                       :formatter="btimeFormat"/>
      <el-table-column label="订单号 / 订货时间 / 供货商" width="230" :resizable="false">
        <template slot-scope="scope">
          <span style="margin-right: 25px">{{'#' + (Array(7).join('0') + scope.row.order.id).slice(-7)}}</span>
          <span>{{otimeFormat(scope.row.order.time)}}</span>
          <el-divider/>
          <span style="margin-right: 5px">{{scope.row.order.seller.name}}</span>
          <el-tag type="warning">{{payFormat(scope.row.order.seller.pay)}}</el-tag>
          <el-tag type="primary">{{scope.row.getBook?'代退('+scope.row.getAdmin+')':
            getFormat(scope.row.order.seller.getmode)}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="书号 / 定价 / 书名" width="320" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <div style="float:left">
              <span style="margin-right: 25px">{{scope.row.book.isbn}}</span>
              <span style="margin-right: 25px">{{'￥'+priceFormat(scope.row.book.price)}}</span>
              <el-tag v-if="isGetBook && !scope.row.getBook" type="primary">{{scope.row.getAdmin}}</el-tag>
              <el-tag v-if="scope.row.send" type="success">代退已送</el-tag>
              <el-divider/>
              <span>{{scope.row.book.title}}</span>
            </div>
            <span v-if="scope.row.comment!==undefined && scope.row.comment!==''"
                  class="comment">网店备注：{{scope.row.comment}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="折扣 / 库位" width="125" :resizable="false">
        <template slot-scope="scope">
          <span>{{priceFormat(scope.row.order.detail.discount)}}</span>
          <el-divider/>
          <span :style="{color: scope.row.order.detail.position===undefined?'#C0C4CC':'#606266'}">
            {{scope.row.order.detail.position===undefined?
            '暂无库位':scope.row.order.detail.position}}</span>
        </template>
      </el-table-column>
      <el-table-column label="退数 / 书款" width="84" :resizable="false">
        <template slot-scope="scope">
          <span>{{scope.row.backNum}}</span>
          <el-divider/>
          <span>{{priceFormat(scope.row.backPrice)}}</span>
        </template>
      </el-table-column>
      <el-table-column width="100" :resizable="false">
        <template slot="header">
          <div style="float: left">
            退款信息
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                实退现款：不计入账本，仅作为退货退款记录<br><br>
                退货记账：计入账本<br><br>
                <span style="color: #C0C4CC">
                  * 批量记账，以默认书款记账<br>
                  * 退款信息设置后无法修改</span>
              </div>
              <i class="el-icon-info" style="color: #C0C4CC"/>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-link v-if="!scope.row.account" class="link" :underline="false"
                   @click="openPay(scope.row)" style="color:#C0C4CC">设置
          </el-link>
          <div v-if="scope.row.account">
            <div v-if="scope.row.cashPrice>0">
              <el-tag type="warning">现款</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.cashPrice)}}</span>
            </div>
            <div v-if="scope.row.accountPrice>0">
              <el-tag type="warning">记账</el-tag>
              <span style="color: #EB6100;margin-left: 5px">{{priceFormat(scope.row.accountPrice)}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visiblePay" custom-class="dialogPay" append-to-body
               :close-on-click-modal="true">
      <div v-if="data!==undefined" style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF;margin-bottom: 20px">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;margin-right: 30px">
            {{data.order.seller.name}}</span>
          <span
            style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #EB6100">书款 {{priceFormat(data.backPrice)}}</span>
        </div>
        <div style="display: flex;justify-content: center;height: 128px">
          <div style="display: flex;flex-direction: column">
            <div v-if="data.order.seller.pay==='0'"
                 style="display: flex;align-items: center;margin-bottom: 10px">
              <span style="font-size: 12px;color: #409EFF;margin-right: 10px">实退现款</span>
              <el-input-number class="family1" size="mini" v-model="data.cashPrice"
                               :controls="false" :precision="2" :min="0" :max="9999.99"/>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 10px">
              <span style="font-size: 12px;color: #409EFF;margin-right: 10px">
              退货记账</span>
              <el-input-number class="family1" size="mini" v-model="data.accountPrice"
                               :controls="false" :precision="2" :min="0" :max="9999.99"/>
            </div>
            <el-popconfirm
              v-if="(data.cashPrice===undefined?0:data.cashPrice)+(data.accountPrice===undefined?0:data.accountPrice)!==data.backPrice"
              title="退款金额与书款不一致，确定保存？" @confirm="updAccount(data)">
              <el-button slot="reference" type="success" size="mini"
                         style="margin-left: 58px;width: 85px"
                         icon="el-icon-check" :loading="data.loading">保存
              </el-button>
            </el-popconfirm>
            <el-button
              v-if="(data.cashPrice===undefined?0:data.cashPrice)+(data.accountPrice===undefined?0:data.accountPrice)===data.backPrice"
              @click="updAccount(data)"
              type="success" size="mini"
              style="margin-left: 58px;width: 85px"
              icon="el-icon-check" :loading="data.loading">保存
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleCommentBatch" custom-class="dialogPay" append-to-body
               :close-on-click-modal="true">
      <div style="display: flex;flex-direction: column;width: 350px">
        <div style="display: flex;justify-content: center;height: 41px;
        background-color: #ECF5FF">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399;">
            设置选中退货备注</span>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;height: 148px">
          <div>
            <div style="display: flex;align-items: start;margin-bottom: 10px">
              <el-input class="family1" size="mini" v-model.trim="comment"
                        type="textarea" :rows="3" style="width: 270px" maxlength="100"
                        resize="none" placeholder="退货备注，供货商端可见"
                        @keyup.enter.native="updCommentBatch"/>
            </div>
            <el-button @click="updCommentBatch" type="success" size="mini"
                       style="width: 85px" icon="el-icon-check" :loading="loadingCommentBatch">保存
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.getBuyerGetInfo()

      this.id = sessionStorage.getItem('orderbackId')
      if (this.id !== null) {
        sessionStorage.clear()
        this.getBack(this.id)
      } else {
        this.getBackList()
      }
    },
    mounted () {
      window.addEventListener('keydown', code => {
        if (code.keyCode === 16 && code.shiftKey) {
          this.pin = true
        }
      })
      window.addEventListener('keyup', code => {
        if (code.keyCode === 16) {
          this.pin = false
        }
      })
    },
    data () {
      return {
        query: {
          name: '',
          type: 'isbn',
          value: '',
          pay: '',
          account: '',
          get: '',
          page: 1
        },
        backs: [],
        total: 0,
        countnum: 0,
        countprice: 0,
        countpay: 0,
        isGetBook: false,
        id: null,

        loadingDlo: false,
        visibleAcc: false,
        loadingAcc: false,
        visibleCom: false,
        loadingCom: false,

        visiblePay: false,
        data: undefined,

        origin: -1,
        pin: false,
        accCheck: undefined,
        comCheck: undefined,

        comment: '',
        visibleCommentBatch: false,
        loadingCommentBatch: false
      }
    },
    methods: {
      btimeFormat (row) {
        const date = new Date(row.time)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      otimeFormat (value) {
        const date = new Date(value)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      payFormat (value) {
        if (value === '0') {
          return '日结'
        } else if (value === '1') {
          return '月结'
        } else if (value === '2') {
          return '预付'
        }
      },
      getFormat (value) {
        if (value === '0') {
          return '自退'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      getRowKeys (row) {
        return row.id
      },

      typeChange () {
        this.query.value = ''
      },
      selectBack () {
        this.$refs.multipleTable.clearSelection()
        this.query.page = 1
        this.getBackList()
      },
      pageChange (page) {
        this.query.page = page
        this.getBackList()
      },
      pinSelect (item, index) {
        const data = this.$refs.multipleTable.tableData // 获取所以数据
        const origin = this.origin // 起点数 从-1开始
        const endIdx = index.index // 终点数
        if (this.pin && item.includes(data[origin])) { // 判断按住
          const sum = Math.abs(origin - endIdx) + 1// 这里记录终点
          const min = Math.min(origin, endIdx)// 这里记录起点
          let i = 0
          while (i < sum) {
            const index = min + i
            this.$refs.multipleTable.toggleRowSelection(data[index], true) // 通过ref打点调用toggleRowSelection方法，第二个必须为true
            i++
          }
        } else {
          this.origin = index.index // 没按住记录起点
        }
      },

      async getBack (id) {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getBack',
            { params: { id: id } })

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.backs = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.backNum
          this.countprice = res.data.count.backPrice
          this.countpay = res.data.count.accountPrice
          this.backs = res.data.data
          this.backs = this.backs.map(item => {
            this.$set(item, 'loading', false)
            return item
          })
          this.backs.forEach((item, index) => {
            item.index = index
          })

          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.backs = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async getBackList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getBackList', { params: this.query })

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.backs = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.backNum
          this.countprice = res.data.count.backPrice
          this.countpay = res.data.count.accountPrice
          this.backs = res.data.data
          this.backs = this.backs.map(item => {
            this.$set(item, 'loading', false)
            return item
          })
          this.backs.forEach((item, index) => {
            item.index = index
          })

          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.backs = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      addBack () {
        this.$router.push({
          name: 'addback'
        })
      },
      async download () {
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要下载的退货！')
          return
        }

        this.loadingDlo = true

        try {
          const orders = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            orders.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const res = await this.$http.post('downloadBack',
            orders, { responseType: 'blob' })

          this.loadingDlo = false

          if (res.headers['content-type'] !== ('application/zip')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = this.nowDate() + ' 退货单.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$refs.multipleTable.clearSelection()
          this.$msg.success('下载成功！')
        } catch (e) {
          this.loadingDlo = false
          this.$msg.error(this.netError)
        }
      },
      async updAccountBatch () {
        if (this.accCheck !== '1234') {
          this.$msg.warning('输入1234后才可记账！')
          return
        }
        this.visibleAcc = false
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要记账的退货！')
          return
        }
        this.loadingAcc = true

        try {
          const backs = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            backs.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post('updBackAccountBatch', backs)
          this.loadingAcc = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.selectBack()
          this.$msg.success('记账成功！')
        } catch (e) {
          this.loadingAcc = false
          this.$msg.error(this.netError)
        }
      },
      async updComplateBatch () {
        if (this.comCheck !== '1234') {
          this.$msg.warning('输入1234后才可完成！')
          return
        }
        this.visibleCom = false
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要完成的退货！')
          return
        }
        this.loadingCom = true

        try {
          const backs = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            backs.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post('updBackComplateBatch', backs)

          this.loadingCom = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.selectBack()
          this.$msg.success('操作成功！')
        } catch (e) {
          this.loadingCom = false
          this.$msg.error(this.netError)
        }
      },
      getComplate () {
        this.$router.push({
          name: 'complateback'
        })
      },

      openPay (row) {
        row.cashPrice = 0
        row.accountPrice = row.backPrice
        this.data = row
        this.visiblePay = true
      },
      async updAccount (row) {
        if (row.cashPrice === undefined) {
          row.cashPrice = 0
        }
        if (row.accountPrice === undefined) {
          row.accountPrice = 0
        }
        if (row.cashPrice === 0 && row.accountPrice === 0) {
          this.$msg.warning('金额不能为0！')
          return
        }

        this.$set(row, 'loading', true)

        try {
          const { data: res } = await this.$http.get('updBackAccount',
            {
              params: {
                id: row.id,
                cashPrice: row.cashPrice,
                accountPrice: row.accountPrice
              }
            })
          this.$set(row, 'loading', false)

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visiblePay = false
          this.$set(row, 'account', true)
          this.$msg.success('设置成功！')
        } catch (e) {
          this.$set(row, 'loading', false)
          this.$msg.error(this.netError)
        }
      },

      async getBuyerGetInfo () {
        try {
          const { data: res } = await this.$http.get('getBuyerGetInfo')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.isGetBook = res.data.getBook
          }
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      nowDate () {
        const date = new Date()
        return (date.getMonth() + 1) + '-' + date.getDate()
      },
      initAccCheck () {
        this.accCheck = undefined
      },
      initComCheck () {
        this.comCheck = undefined
      },

      openCommentBatch () {
        if (this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要备注的退货！')
          return
        }
        this.comment = ''
        this.visibleCommentBatch = true
      },
      async updCommentBatch () {
        this.loadingCommentBatch = true
        try {
          const backs = []
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            backs.push({ id: this.$refs.multipleTable.selection[i].id })
          }

          const { data: res } = await this.$http.post(
            'updBackCommentBatch', backs, { params: { comment: this.comment } })

          this.loadingCommentBatch = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.visibleCommentBatch = false
          this.selectBack()
          this.$msg.success('备注成功！')
        } catch (e) {
          this.loadingCommentBatch = false
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText1 {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px;
    margin-left: 3px;
    padding: 0;
    color: #67C23A;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .dialogPay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 189px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  .el-divider--horizontal {
    margin: 1px 0;
    height: 1px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-divider--vertical {
    margin: 0 20px;
  }

  .el-input-number {
    width: 85px !important;
  }

  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }

  .comment {
    color: #EB6100;
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.85);
  }
</style>
